<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Tipo de Habitación - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>
             <b-col md="6">
                  <b-form-group label="Nombre:">
                    <b-form-input
                      type="text"
                      disabled
                      ref="name"
                      v-model="type_room.name"
                      placeholder="Ingrese su nombre"
                    ></b-form-input>
               
                  </b-form-group>
                </b-col>

          
                <b-col md="6">
                  <b-form-group label="Cambiar Foto:">
                    <b-form-file
                    disabled
                      @change="onFileChange"
                      placeholder="Seleccione un foto..."
                      drop-placeholder="Suelta la imagen aquí..."
                    ></b-form-file>
                    </b-form-group>
                </b-col>

                
                <b-col md="10">
                  <b-form-group label="Descripción :" required>
                    <b-form-textarea
                      v-model="type_room.description"
                      disabled
                      rows="2"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      v-model="type_room.state"
                      disabled
                      class="form-control"
                    >
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>

                 <b-col md="12">
                  <b-form-group label="Foto:" class="text-center">
                    <img class="img-fluid" :src="url_base + type_room.photo" alt="">
                    </b-form-group>
                </b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-link
                    class="btn form-control btn-primary"
                    :to="{ path: '/tipo-habitacion/listar' }"
                    append
                    >REGRESAR</b-link
                  >
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "CategoriaView",
  props: ["id_type_room"],
  data() {
    return {
      module:'TypeRoom',
      type_room: {
        id_type_room: "",
        name: "",
        photo: "",
        description: "",
           beds: 1,
        plazas: "",
        maximum_guest: 1,
        minimum_guest: 1,
        state: 1,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewTypeRoom();
  },
  methods: {
    ViewTypeRoom,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


//ver usuario
function ViewTypeRoom() {
  let id_type_room = je.decrypt(this.id_type_room);
  let me = this;
  let url = this.url_base + "type-room/view/" + id_type_room;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 5,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.type_room.id_type_room = response.data.result.id_type_room;
        me.type_room.name = response.data.result.name;
        me.type_room.photo = response.data.result.photo;
        me.type_room.description = response.data.result.description;
        me.type_room.state = response.data.result.state;

        me.type_room.beds = response.data.result.beds;
        me.type_room.plazas = response.data.result.plazas;
        me.type_room.maximum_guest = response.data.result.maximum_guest;
        me.type_room.minimum_guest = response.data.result.minimum_guest;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {

    });
}

</script>
